import * as React from "react";
import { Link } from "gatsby";

import Bridge from "../../components/Bridge.js";
// import Ch from "../../components/Chord.js";
import Chorus from "../../components/Chorus.js";
import Columns from "../../components/Columns.js";
import { Helmet } from "react-helmet";
import Img from "gatsby-image";
import Layout from "../../components/Layout";
import PreChorus from "../../components/PreChorus.js";
import Verse from "../../components/Verse.js";
import { graphql } from "gatsby";

export default function MarinersRevengeSong({ data }) {
  return (
    <Layout>
      <Helmet title="Mariner's Revenge Song | The Decemberists" />

      <h1>Mariner's Revenge Song</h1>

      <h2>The Decemberists</h2>

      <h4>Key: Am</h4>

      <h4>Tempo: 92</h4>

      <Link to="/">List</Link>

      <Img fluid={data.file.childImageSharp.fluid} />

      <Columns columns="2">
        <Verse>
            <p>We are two mariners</p>
            <p>Our ships' sole survivors</p>
            <p>In this belly of a whale</p>
            <p>Its ribs are ceiling beams</p>
            <p>Its guts are carpeting</p>
            <p>I guess we have some time to kill</p>
        </Verse>
        <Verse>
          <p>You may not remember me</p>
          <p>I was a child of three</p>
          <p>And you, a lad of eighteen</p>
          <p>But I remember you</p>
          <p>And I will relay to you</p>
          <p>How our histories interweave</p>
        </Verse>
        <PreChorus>
          <p>At the time you were</p>
          <p>A rake and a roustabout</p>
          <p>Spending all your money</p>
          <p>On the whores and hounds</p>
          <p>Oh</p>
        </PreChorus>
        <Verse>
          <p>You had a charming air</p>
          <p>All cheap and debonair</p>
          <p>My widowed mother found so sweet</p>
          <p>And so she took you in</p>
          <p>Her sheets still warm with him</p>
          <p>Now filled with filth and foul disease</p>
        </Verse>
        <PreChorus>
          <p>As time wore on you proved</p>
          <p>A debt-ridden drunken mess</p>
          <p>Leaving my mother</p>
          <p>A poor consumptive wretch</p>
          <p>Oh, oh</p>
        </PreChorus>
        <Verse>
          <p>And then you disappeared</p>
          <p>Your gambling arrears</p>
          <p>The only thing you left behind</p>
          <p>And then the magistrate</p>
          <p>Reclaimed our small estate</p>
          <p>And my poor mother lost her mind</p>
        </Verse>
        <PreChorus>
          <p>Then, one day in spring</p>
          <p>My dear sweet mother died</p>
          <p>But before she did</p>
          <p>I took her hand as she, dying, cried</p>
          <p>Oh, oh</p>
        </PreChorus>
        <Chorus>
          <p>"Find him, bind him</p>
          <p>Tie him to a pole and break</p>
          <p>His fingers to splinters</p>
          <p>Drag him to a hole</p>
          <p>Until he wakes up naked</p>
          <p>Clawing at the ceiling of his grave"</p>
        </Chorus>
        <Verse>
          <p>It took me fifteen years</p>
          <p>To swallow all my tears</p>
          <p>Among the urchins in the street</p>
          <p>Until a priory</p>
          <p>Took pity and hired me</p>
          <p>To keep their vestry nice and neat</p>
        </Verse>
        <PreChorus>
          <p>But never once in the employ</p>
          <p>Of these holy men</p>
          <p>Did I ever once turn my mind</p>
          <p>From the thought of revenge</p>
          <p>Oh, oh</p>
        </PreChorus>
        <Verse>
          <p>One night I overheard</p>
          <p>The prior exchanging words</p>
          <p>With a penitent whaler from the sea</p>
          <p>The captain of his ship</p>
          <p>Who matched you toe to tip</p>
          <p>Was known for wanton cruelty</p>
        </Verse>
        <PreChorus>
          <p>The following day</p>
          <p>I shipped to sea with a privateer</p>
          <p>And in the whistle of the wind</p>
          <p>I could almost hear</p>
          <p>Oh, oh</p>
        </PreChorus>
        <Chorus>
          <p>"Find him, bind him</p>
          <p>Tie him to a pole and break</p>
          <p>His fingers to splinters</p>
          <p>Drag him to a hole</p>
          <p>Until he wakes up, naked</p>
          <p>Clawing at the ceiling of his grave</p>
        </Chorus>
        <Bridge>
          <p>There is one thing I must say to you</p>
          <p>As you sail across the sea</p>
          <p>Always, your mother will watch over you</p>
          <p>As you avenge this wicked deed"</p>
        </Bridge>
        <Verse>
          <p>And then, that fateful night</p>
          <p>We had you in our sight</p>
          <p>After twenty months at sea</p>
          <p>Your starboard flank abeam</p>
          <p>I was getting my muskets clean</p>
          <p>When came this rumbling from beneath</p>
        </Verse>
        <PreChorus>
          <p>The ocean shook</p>
          <p>The sky went black</p>
          <p>And the captain quailed</p>
          <p>And before us grew</p>
          <p>The angry jaws</p>
          <p>Of a giant whale</p>
          <p>Oh, oh, oh, oh</p>
        </PreChorus>
        <Verse>
          <p>Don't know how I survived</p>
          <p>The crew all was chewed alive</p>
          <p>I must have slipped between his teeth</p>
          <p>But, oh, what providence</p>
          <p>What divine intelligence</p>
          <p>That you should survive as well as me</p>
        </Verse>
        <PreChorus>
          <p>It gives my heart great joy</p>
          <p>To see your eyes fill with fear</p>
          <p>So lean in close and I will whisper</p>
          <p>The last words you'll hear</p>
          <p>Oh, oh</p>
        </PreChorus>
      </Columns>
    </Layout>
  );
}

export const query = graphql`
  query {
    file(relativePath: { eq: "mariners-revenge-song.png" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fluid(maxWidth: 1600, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
  }
`;
